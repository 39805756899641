:root {
  --default-font: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Helvetica, sans-serif;
  --titles-font: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Helvetica, sans-serif;
}

button {
  all: unset;
}

.SlideViewerTextSlide {
  font-family: var(--default-font);
  line-height: normal;
  font-size: 1.5rem;
  color: #000000;
}

.SlideViewerTextSlide.TeacherInfoSidebar {
  font-size: 1.5rem;
  color: #000000;
}

.SlideViewerTextSlide a {
  color: #0782c1;
}

.SlideViewerTextSlide img {
  min-width: 20px;
  min-height: 20px;
  display: inline-block !important;
}

.SlideViewerTextSlide h1 {
  margin-bottom: 1.5em;
  min-height: 1rem;
  font-size: 2rem;
  line-height: 1.28571429em;
  margin-bottom: 2rem;
  font-weight: 700;
  padding: 0;
}

.SlideViewerTextSlide h::before {
  margin-top: 0;
}

.SlideViewerTextSlide p {
  margin: 0 0 1em;
}

.SlideViewerTextSlide p:last-child:not(:first-child) {
  margin: 0 !important;
}

table {
  border-collapse: collapse;
}

.SlideViewerTextSlide .TableBorder table {
  border: 1px solid black;
}

.SlideViewerTextSlide .TableBorder.cke_show_border > tbody > tr > td {
  border: 1px solid black !important;
}

.SlideViewerTextSlide .TableBorder th {
  border: 1px solid black;
}
.SlideViewerTextSlide .TableBorder td {
  border: 1px solid black;
}

.SlideViewerTextSlide .fg-zwart {
  color: #000000;
}

.SlideViewerTextSlide .bg-zwart {
  background-color: #000000;
}

.SlideViewerTextSlide .fg-wit {
  color: #ffffff;
}

.SlideViewerTextSlide .bg-wit {
  background-color: #ffffff;
}

.SlideViewerTextSlide .fg-extra-green {
  color: #008000;
}

.SlideViewerTextSlide .bg-extra-green {
  background-color: #008000;
}

.SlideViewerTextSlide .fg-extra-blue {
  color: #0000ff;
}

.SlideViewerTextSlide .bg-extra-blue {
  background-color: #0000ff;
}

.SlideViewerTextSlide .fg-extra-red {
  color: #ff0000;
}

.SlideViewerTextSlide .bg-extra-red {
  background-color: #ff0000;
}

.SlideViewerTextSlide .fg-sc3-beweging {
  color: #4c97ff;
}

.SlideViewerTextSlide .bg-sc3-beweging {
  background-color: #4c97ff;
}

.SlideViewerTextSlide .fg-sc3-uiterlijk {
  color: #9966ff;
}

.SlideViewerTextSlide .bg-sc3-uiterlijk {
  background-color: #9966ff;
}

.SlideViewerTextSlide .fg-sc3-geluid {
  color: #cf63cf;
}

.SlideViewerTextSlide .bg-sc3-geluid {
  background-color: #cf63cf;
}

.SlideViewerTextSlide .fg-sc3-pen {
  color: #0fbd8c;
}

.SlideViewerTextSlide .bg-sc3-pen {
  background-color: #0fbd8c;
}

.SlideViewerTextSlide .fg-sc3-data {
  color: #ff8c1a;
}

.SlideViewerTextSlide .bg-sc3-data {
  background-color: #ff8c1a;
}

.SlideViewerTextSlide .fg-sc3-gebeurtenissen {
  color: #ffbf00;
}

.SlideViewerTextSlide .bg-sc3-gebeurtenissen {
  background-color: #ffbf00;
}

.SlideViewerTextSlide .fg-sc3-besturen {
  color: #ffab19;
}

.SlideViewerTextSlide .bg-sc3-besturen {
  background-color: #ffab19;
}

.SlideViewerTextSlide .fg-sc3-waarnemen {
  color: #5cb1d6;
}

.SlideViewerTextSlide .bg-sc3-waarnemen {
  background-color: #5cb1d6;
}

.SlideViewerTextSlide .fg-sc3-functies {
  color: #59c059;
}

.SlideViewerTextSlide .bg-sc3-functies {
  background-color: #59c059;
}

.SlideViewerTextSlide .fg-sc3-meer-blokken {
  color: #ff6680;
}

.SlideViewerTextSlide .bg-sc3-meer-blokken {
  background-color: #ff6680;
}

.SlideViewerTextSlide .fg-sc3-parameters {
  color: #ff6680;
}

.SlideViewerTextSlide .bg-sc3-parameters {
  background-color: #ff6680;
}

.SlideViewerTextSlide .fg-sc3-lijsten {
  color: #ff661a;
}

.SlideViewerTextSlide .bg-sc3-lijsten {
  background-color: #ff661a;
}

.SlideViewerTextSlide .fg-js-basic {
  color: #1d78d7;
}

.SlideViewerTextSlide .bg-js-basic {
  background-color: #1d78d7;
}

.SlideViewerTextSlide .fg-js-input {
  color: #b6409e;
}

.SlideViewerTextSlide .bg-js-input {
  background-color: #b6409e;
}

.SlideViewerTextSlide .fg-js-music {
  color: #d83d2f;
}

.SlideViewerTextSlide .bg-js-music {
  background-color: #d83d2f;
}

.SlideViewerTextSlide .fg-js-led {
  color: #5c2d91;
}

.SlideViewerTextSlide .bg-js-led {
  background-color: #5c2d91;
}

.SlideViewerTextSlide .fg-js-radio {
  color: #e5478c;
}

.SlideViewerTextSlide .bg-js-radio {
  background-color: #e5478c;
}

.SlideViewerTextSlide .fg-js-loops {
  color: #3f7d14;
}

.SlideViewerTextSlide .bg-js-loops {
  background-color: #3f7d14;
}

.SlideViewerTextSlide .fg-js-logic {
  color: #2d6a70;
}

.SlideViewerTextSlide .bg-js-logic {
  background-color: #2d6a70;
}

.SlideViewerTextSlide .fg-js-variables {
  color: #a92b22;
}

.SlideViewerTextSlide .bg-js-variables {
  background-color: #a92b22;
}

.SlideViewerTextSlide .fg-js-math {
  color: #712872;
}

.SlideViewerTextSlide .bg-js-math {
  background-color: #712872;
}

.SlideViewerTextSlide .fg-js-functions {
  color: #165b9e;
}

.SlideViewerTextSlide .bg-js-functions {
  background-color: #165b9e;
}

.SlideViewerTextSlide .fg-js-arrays {
  color: #a94422;
}

.SlideViewerTextSlide .bg-js-arrays {
  background-color: #a94422;
}

.SlideViewerTextSlide .fg-js-text {
  color: #99661c;
}

.SlideViewerTextSlide .bg-js-text {
  background-color: #99661c;
}

.SlideViewerTextSlide .fg-js-game {
  color: #3d8372;
}

.SlideViewerTextSlide .bg-js-game {
  background-color: #3d8372;
}

.SlideViewerTextSlide .fg-js-images {
  color: #5c2d91;
}

.SlideViewerTextSlide .bg-js-images {
  background-color: #5c2d91;
}

.SlideViewerTextSlide .fg-js-pins {
  color: #a92b22;
}

.SlideViewerTextSlide .bg-js-pins {
  background-color: #a92b22;
}

.SlideViewerTextSlide .fg-js-serial {
  color: #002050;
}

.SlideViewerTextSlide .bg-js-serial {
  background-color: #002050;
}

.SlideViewerTextSlide .fg-js-control {
  color: #333333;
}

.SlideViewerTextSlide .bg-js-control {
  background-color: #333333;
}

.SlideViewerTextSlide .fg-mc-player {
  color: #0078d7;
}

.SlideViewerTextSlide .bg-mc-player {
  background-color: #0078d7;
}

.SlideViewerTextSlide .fg-mc-blocks {
  color: #7abb55;
}

.SlideViewerTextSlide .bg-mc-blocks {
  background-color: #7abb55;
}

.SlideViewerTextSlide .fg-mc-mobs {
  color: #764bcc;
}

.SlideViewerTextSlide .bg-mc-mobs {
  background-color: #764bcc;
}

.SlideViewerTextSlide .fg-mc-agent {
  color: #d83b01;
}

.SlideViewerTextSlide .bg-mc-agent {
  background-color: #d83b01;
}

.SlideViewerTextSlide .fg-mc-builder {
  color: #cb48b7;
}

.SlideViewerTextSlide .bg-mc-builder {
  background-color: #cb48b7;
}

.SlideViewerTextSlide .fg-mc-gameplay {
  color: #8f6d40;
}

.SlideViewerTextSlide .bg-mc-gameplay {
  background-color: #8f6d40;
}

.SlideViewerTextSlide .fg-mc-positions {
  color: #69b090;
}

.SlideViewerTextSlide .bg-mc-positions {
  background-color: #69b090;
}

.SlideViewerTextSlide .fg-mc-loops {
  color: #569138;
}

.SlideViewerTextSlide .bg-mc-loops {
  background-color: #569138;
}

.SlideViewerTextSlide .fg-mc-logic {
  color: #459197;
}

.SlideViewerTextSlide .bg-mc-logic {
  background-color: #459197;
}

.SlideViewerTextSlide .fg-mc-variables {
  color: #ea2b1f;
}

.SlideViewerTextSlide .bg-mc-variables {
  background-color: #ea2b1f;
}

.SlideViewerTextSlide .fg-mc-math {
  color: #6c6ea0;
}

.SlideViewerTextSlide .bg-mc-math {
  background-color: #6c6ea0;
}

.SlideViewerTextSlide .fg-mc-advanced {
  color: #3c3c3c;
}

.SlideViewerTextSlide .bg-mc-advanced {
  background-color: #3c3c3c;
}

.SlideViewerTextSlide .fg-mc-functions {
  color: #235789;
}

.SlideViewerTextSlide .bg-mc-functions {
  background-color: #235789;
}

.SlideViewerTextSlide .fg-mc-arrays {
  color: #a94400;
}

.SlideViewerTextSlide .bg-mc-arrays {
  background-color: #a94400;
}

.SlideViewerTextSlide .fg-mc-text {
  color: #e89005;
}

.SlideViewerTextSlide .bg-mc-text {
  background-color: #e89005;
}

.SlideViewerTextSlide .fg-mc-shapes {
  color: #ec7505;
}

.SlideViewerTextSlide .bg-mc-shapes {
  background-color: #ec7505;
}

.SlideViewerTextSlide .fg-mc-extensions {
  color: #717171;
}

.SlideViewerTextSlide .bg-mc-extensions {
  background-color: #717171;
}

.SlideViewerTextSlide pre {
  overflow-wrap: break-word;
  white-space: pre-wrap;
  background-color: rgba(0, 0, 0, 0.05);
  font-family: monospace;
  font-size: 16px;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid #9f3a38;
  background: #fff6f6;
  margin: 1em 0;
}

.SlideViewerTextSlide content {
  font-family: monospace, monospace;
  font-size: 1em;
}

.SlideViewerTextSlide a[class^='LINKSTYLE'] {
  background: #4183c4;
  padding: 0.8em 50px 0.8em 0.8em;
  margin-top: 1em;
  margin-bottom: 1em;
  width: auto;
  color: #fff;
  border-radius: 5px;
  position: relative;
  display: inline-flex;
  align-items: center;
}

.SlideViewerTextSlide a[class^='LINKSTYLE']:hover {
  background: darken(#4183c4, 10%);
  color: #fff;
}

.SlideViewerTextSlide a[class^='LINKSTYLE']::after {
  background-size: 30px 30px;
  display: inline-block;
  width: 30px;
  height: 30px;
  right: 10px;
  position: absolute;
  content: '';
}

.SlideViewerTextSlide a.LINKSTYLE_SCRATCH::after {
  background-image: url('https://codefeverfilestorage.blob.core.windows.net/content/code-scratch.png');
}

.SlideViewerTextSlide a.LINKSTYLE_ERIDERS::after {
  background-image: url('https://codefeverfilestorage.blob.core.windows.net/content/code-js.png');
}

.SlideViewerTextSlide a[class^='EXERCISE'] {
  background: #4183c4;
  padding: 0.8em 50px 0.8em 0.8em;
  margin-top: 1em;
  margin-bottom: 1em;
  width: auto;
  color: #fff;
  border-radius: 5px;
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
}

.SlideViewerTextSlide a[class^='EXERCISE']:hover {
  background: #3269a0;
  color: #fff;
}

.SlideViewerTextSlide a[class^='EXERCISE']::after {
  background-size: 30px 30px;
  display: inline-block;
  width: 30px;
  height: 30px;
  right: 10px;
  position: absolute;
  content: '';
}

.SlideViewerTextSlide a.EXERCISE_SCRATCH::after {
  background-image: url('https://codefeverfilestorage.blob.core.windows.net/content/code-scratch.png');
}

.SlideViewerTextSlide a.EXERCISE_ERIDERS::after {
  background-image: url('https://codefeverfilestorage.blob.core.windows.net/content/code-js.png');
}

.SlideViewerTextSlide ol {
  list-style-type: decimal;
  padding: 0 40px;
  margin: 1em 0;
}

.SlideViewerTextSlide ul {
  list-style-type: initial;
  padding: 0 40px;
  margin: 1em 0;
}

.SlideViewerTextSlide li {
  list-style-type: inherit;
  list-style-position: outside;
}

.SlideViewerTextSlide ol,
.SlideViewerTextSlide ul,
.SlideViewerTextSlide li,
.SlideViewerTextSlide code,
.SlideViewerTextSlide blockquote {
  line-height: normal;
}

blockquote {
  display: block;
  background-color: #ffffff;
  color: #000000;
  font-family: var(--default-font);
  font-size: 22px;
  font-style: italic;
  border-style: solid;
  border-color: #ccc;
  border-width: 0;
  border-left-width: 5px;
  padding-left: 20px;
  padding-right: 8px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
}

.SlideViewerTextSlide a.SlideViewer__StyledButton {
  border-radius: 7px;
  padding: 4px;
  padding-right: 0px;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  width: max-content;
  font-size: large;
  font-weight: 600;
  cursor: pointer;
  transition-duration: 200ms;
}

.SlideViewerTextSlide a.SlideViewer__StyledButton:hover {
  box-shadow: none;
}

.SlideViewerTextSlide a.SlideViewer__StyledButton::before {
  width: 20px;
  object-fit: cover;
  height: 20px;
  margin-right: 0.7rem;
  margin-left: 0.5rem;
  content: '';
}

.SlideViewerTextSlide a.SlideViewer__StyledButton::after {
  background-size: 30px 12px;
  width: 30px;
  height: 12px;
  margin-left: 4px;
  content: '';
  margin-right: 7px;
  transition-duration: 200ms;
}

.SlideViewerTextSlide a.SlideViewer__StyledButton:hover::after {
  margin-left: 10px;
  margin-right: 0px;
}

.SlideViewerTextSlide button.SlideViewer__StyledButton {
  border-radius: 7px;
  padding: 4px;
  padding-right: 0px;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  width: max-content;
  font-size: large;
  font-weight: 600;
  cursor: pointer;
  transition-duration: 200ms;
  -webkit-user-modify: read-only;
  -moz-user-modify: read-only;
}

.SlideViewerTextSlide button.SlideViewer__StyledButton:hover {
  box-shadow: none;
}

.SlideViewerTextSlide button.SlideViewer__StyledButton::before {
  width: 20px;
  object-fit: cover;
  height: 20px;
  margin-right: 0.7rem;
  margin-left: 0.5rem;
  content: '';
}

.SlideViewerTextSlide button.SlideViewer__StyledButton::after {
  background-size: 30px 12px;
  width: 30px;
  height: 12px;
  margin-left: 4px;
  content: '';
  margin-right: 7px;
  transition-duration: 200ms;
}

.SlideViewerTextSlide button.SlideViewer__StyledButton:hover::after {
  margin-left: 10px;
  margin-right: 0px;
}

.SlideViewerTextSlide .SlideViewer__ModalLink {
  color: #0284c7 !important;
  background-color: #e0f2fe;
}

.SlideViewerTextSlide .SlideViewer__ModalLink:hover {
  background-color: #33b7f03a !important;
}

.SlideViewerTextSlide .SlideViewer__ModalLink::before {
  background-repeat: no-repeat;
  height: 16px !important;
  background-image: url('https://codefeverpublic.blob.core.windows.net/icons/modal.svg');
}

.SlideViewerTextSlide .SlideViewer__ExternalLink {
  color: #0284c7 !important;
  background-color: #e0f2fe;
}

.SlideViewerTextSlide .SlideViewer__ExternalLink:hover {
  background-color: #33b7f03a !important;
}

.SlideViewerTextSlide .SlideViewer__ExternalLink::before {
  background-image: url('https://codefeverpublic.blob.core.windows.net/icons/world.svg');
}

.SlideViewerTextSlide .SlideViewer__ExternalLink::after {
  background-image: url('https://codefeverpublic.blob.core.windows.net/icons/arrow-blue.svg');
}

.SlideViewerTextSlide .SlideViewer__SlideLink {
  color: #ea580c !important;
  background-color: #ffedd5;
}

.SlideViewerTextSlide .SlideViewer__SlideLink:hover {
  background-color: #ffedd5 !important;
}

.SlideViewerTextSlide .SlideViewer__SlideLink::before {
  background-image: url('https://codefeverpublic.blob.core.windows.net/icons/slide.svg');
  background-repeat: no-repeat;
  background-size: 100%;
}

.SlideViewerTextSlide .SlideViewer__SlideLink::after {
  background-image: url('https://codefeverpublic.blob.core.windows.net/icons/arrow-orange.svg');
}

.SlideViewerTextSlide .SlideViewer__VideoLink {
  color: #dc2626 !important;
  background: #fee2e2;
}

.SlideViewerTextSlide .SlideViewer__VideoLink:hover {
  background-color: #ea2e202f;
}

.SlideViewerTextSlide .SlideViewer__VideoLink::before {
  background-image: url('https://codefeverpublic.blob.core.windows.net/icons/youtube-brands.svg');
}

.SlideViewerTextSlide .SlideViewer__VideoLink::after {
  background-image: url('https://codefeverpublic.blob.core.windows.net/icons/arrow-red.svg');
}

.SlideViewerTextSlide .SlideViewer__OtherLink {
  background-color: #dcfce7;
  color: #16a34a !important;
}

.SlideViewerTextSlide .SlideViewer__SteamsQR {
  background-color: #ffcfd2;
  color: #99464e !important;
}

.SlideViewerTextSlide .SlideViewer__OtherLink:hover {
  background-color: #22d86b34;
}

.SlideViewerTextSlide .SlideViewer__OtherLink::before {
  background-image: url('https://codefeverpublic.blob.core.windows.net/icons/paper-plane-green.svg');
}

.SlideViewerTextSlide .SlideViewer__OtherLink::after {
  background-image: url('https://codefeverpublic.blob.core.windows.net/icons/arrow-green.svg');
}

.SlideViewerTextSlide .SlideViewer__ExerciseLink {
  color: #f59e0b !important;
  background-color: #fef3c7;
}

.SlideViewerTextSlide .SlideViewer__ExerciseLink:hover {
  background-color: #fcc0272f;
}

.SlideViewerTextSlide .SlideViewer__ExerciseLink::before {
  background-image: url('https://codefeverpublic.blob.core.windows.net/icons/tasks-solid.svg');
}

.SlideViewerTextSlide .SlideViewer__ExerciseLink::after {
  background-image: url('https://codefeverpublic.blob.core.windows.net/icons/arrow-yellow.svg');
}

.SlideViewerTextSlide .SlideViewer__DownloadLink {
  color: #9333ea !important;
  background-color: #f3e8ff;
}

.SlideViewerTextSlide .SlideViewer__DownloadLink:hover {
  background-color: #9c81f82f;
}

.SlideViewerTextSlide .SlideViewer__DownloadLink::before {
  background-image: url('https://codefeverpublic.blob.core.windows.net/icons/cloud-download-purple.svg');
  background-repeat: no-repeat;
  background-size: 100%;
}

.SlideViewerTextSlide .SlideViewer__DownloadLink::after {
  background-image: url('https://codefeverpublic.blob.core.windows.net/icons/arrow-purple.svg');
}

.SlideViewerMultipleChoiceAnswerCard img {
  max-width: 17rem;
  margin: auto;
}
li > p {
  display: inline;
}

ul,
ol {
  margin: 1rem 0;
}
